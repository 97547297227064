import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import SvgIcon from "../../../atoms/icons/svg-icon"
import Tag from "../../../atoms/icons/files/tag.jsx"
import { formatDateStr } from "../../../../lib/util"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"

const Header = ({ title, authorName, authorImg, date, categories, fluid }) => {
  const {
    file: {
      childImageSharp: { fluid: fallback },
    },
  } = useStaticQuery(FALLBACK)

  const prettyDate = formatDateStr(date)

  return (
    <Background fluid={fluid || fallback}>
      <Overlay>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Author>
          <img src={authorImg} alt="Author image" />
          <span>{authorName}</span>
        </Author>
        <Date>{prettyDate}</Date>
        <BadgeGroup>
          {categories.nodes.map(({ name, slug }) => (
            <Badge to={"/sleep-matters/category/" + slug} as={Link} key={slug}>
              <SvgIcon SvgComponent={Tag} hue="white" shade="000" />
              <span dangerouslySetInnerHTML={{ __html: name }} />
            </Badge>
          ))}
        </BadgeGroup>
      </Overlay>
    </Background>
  )
}

const BadgeGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`
const Badge = styled.div`
  background-color: #037cac;
  border-radius: 4px;
  color: white;
  padding: 8px;
  margin: 10px;
  transition: 0.2s all ease-in-out;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 5px 10px;
    margin: 5px;
  }
  &:hover {
    transform: translateY(-2px);
  }
  .icon {
    margin-right: 10px;
  }
`

const Author = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 200px;
    margin-right: 20px;
  }
  span {
    font-size: 20px;
    color: white;
  }
`

const Date = styled.div`
  color: #9ba5ae;
  margin-right: 30px;
  font-size: 14px;
  .icon {
    margin-right: 10px;
  }
`

const Background = styled(BackgroundImage)``
const Overlay = styled.div`
  background: linear-gradient(
    127.54deg,
    rgba(22, 34, 50, 0.76) 0%,
    rgba(34, 50, 71, 0.48) 100%
  );
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  > * {
    max-width: 1024px;
    margin: 10px;
  }
`

const Title = styled.h1`
  color: #ffffff;
  font-size: 48px;
  font-weight: 300;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 30px;
  }
`

const FALLBACK = graphql`
  {
    file(relativePath: { eq: "placeholders/header.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Header
