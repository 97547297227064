import React from "react"
import styled from "styled-components"
import Header from "./Header"
import Inner from "../../../layout/pageInner/Inner"
import CategorySidebar from "../CategorySidebar"
import RecentArticlesContainer from "./RecentArticlesContainer"
import Social from "./Social"
import { Responsive, windowSizes } from "../../../../utils/responsive"

const BlogSingle = ({
  title,
  content,
  date,
  authorName,
  categories,
  fluid,
  authorImg,
  slug,
  location,
}) => {
  return (
    <Container>
      <Header
        title={title}
        authorName={authorName}
        authorImg={authorImg}
        date={date}
        categories={categories}
        fluid={fluid}
      />
      <Body>
        <Inner>
          <Grid>
            <Left>
              <Post dangerouslySetInnerHTML={{ __html: content }} />
            </Left>
            <Right>
              <RecentArticlesContainer currentSlug={slug} />
              <Spacer amount="20px" />
              <CategorySidebar activeCategory={categories.nodes[0].slug} />
            </Right>
          </Grid>
        </Inner>
      </Body>
      <SocialContainer>
        <Inner>
          <SocialGridLayout>
            <Social location={location} slug={slug} title={title} />
          </SocialGridLayout>
        </Inner>
      </SocialContainer>

      <Responsive maxWidth={windowSizes.mobileMedium}>
        <MobileStyling>
          <Inner>
            <RecentArticlesContainer currentSlug={slug} />
            <Spacer amount="20px" />
            <CategorySidebar activeCategory={categories.nodes[0].slug} />
          </Inner>
        </MobileStyling>
      </Responsive>
    </Container>
  )
}

export default BlogSingle

const MobileStyling = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    display: block;
    padding: 40px 0;
  }
`

const SocialGridLayout = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`

const Spacer = styled.div`
  margin-top: ${({ amount = "20px" }) => amount};
`

const SocialContainer = styled.div`
  background-color: #f0f1f3;
  padding: 40px 0;
`

const Container = styled.div``
const Body = styled.div`
  padding: 60px 0;
  padding-bottom: 40px;
`

import redCheck from "../../../../images/icons/red-check.svg"
const Post = styled.div`
  > * {
    margin: 20px 0;
  }

  ul:not(.blocks-gallery-grid) li,
  ol:not(.blocks-gallery-grid) li {
    display: flex;
    > * {
      display: contents;
    }
  }

  ul:not(.blocks-gallery-grid) li:before,
  ol:not(.blocks-gallery-grid) li:before {
    content: url(${redCheck});
    color: red;
    display: block;
    margin-right: 15px;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
  }

  figcaption {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    padding: 5px 10px;
    border-radius: 4px;
    color: #828282;
    background: #e0e0e0;
  }

  blockquote p {
    font-weight: 400;
    font-size: 18px;
    border-left: 8px solid #0988bb;
    padding: 7px;
    padding-left: 15px;
    color: #7d828c;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`

const Left = styled.div``
const Right = styled.div`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    display: none;
  }
`
