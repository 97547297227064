import React from "react"
import { graphql } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import BlogSingle from "../zzz/organisms/blog/single"
import { path } from "../lib/util"
import favicon from "../images/FaviconBed.png"

// A single blog post page
const WordPressPost = ({ location, data }) => {
  const { localWpGraphQlPosts: post } = data

  const {
    title,
    content,
    featuredImage,
    excerpt,
    date,
    author,
    categories,
    uri,
    seo,
    postId: post_id,
  } = post

  return (
    <Layout location={location} background="#fafafa">
      <SEO
        title={`${title}`}
        imageSrc={featuredImage && featuredImage.sourceUrl}
        htmldescription={excerpt}
        location={location}
        post_id={post_id}
        seo={seo}
      />
      <BlogSingle
        location={location}
        title={title}
        slug={uri}
        content={content}
        date={date}
        authorName={"The Mattress Warehouse"}
        categories={categories}
        authorImg={favicon}
        fluid={path(
          [
            "featuredImage",
            "imageFile",
            "localFile",
            "childImageSharp",
            "fluid",
          ],
          post
        )}
      />
    </Layout>
  )
}

export default WordPressPost

export const pageQuery = graphql`
  query GET_POST($postId: Int!) {
    localWpGraphQlPosts(postId: { eq: $postId }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      postId
      title
      content
      excerpt
      uri
      date
      featuredImage {
        sourceUrl
        mediaItemId
        imageFile {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      author {
        name
        slug
        avatar {
          url
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
    }
  }
`
